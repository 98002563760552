import * as React from "react"
import Layout from "../components/layout"
import WideImage from "../components/WideImage"
import { Link } from "gatsby"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import Seo from "../components/seo";
import Navbar from "../components/navbar";

const IndexPage = () => (

  <div className="p-0">
    <Seo title={"Huey"} />
    <Navbar siteTitle={"Huey"} />
    <main>
      <WideImage />
    </main>
    <div className="frame-band">
      <p>Household water and energy efficiency through real-time awareness</p>
    </div>
  </div>
)

export default IndexPage

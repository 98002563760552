import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
//import { useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"

const img_height = 200;

const WideImage = (props) => {
  return (
    <div className="row">
      <StaticImage src="../images/houses-aerial-1200.jpeg" alt="houses aerial"/>
    </div>
  )
}

export default WideImage;

